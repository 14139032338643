export default function usePasswordForm() {
  const newPasswordRules = [
    (password: string) => {
      const minLength = 10
      const minRuleAppliedCount = 3

      if (password.length < minLength) return '10文字以上で入力してください'
      const hasLowerCase = /[a-z]/.test(password)
      const hasUpperCase = /[A-Z]/.test(password)
      const hasNumber = /\d/.test(password)
      const hasSpecialChar = /[!@#$%^&*]/.test(password)
      const typesCount = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialChar].filter(
        Boolean
      ).length

      if (typesCount < minRuleAppliedCount) {
        return '英小文字・英大文字・数字・記号（!@#$%^&*）のうち、3種類以上の文字種別を使用してください。'
      }
      return true
    },
  ]

  return { newPasswordRules }
}
