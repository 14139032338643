<template>
  <FacilityRegistrationLayout
    page-title="新しいパスワードを設定してください"
    :step="1"
    :logo-image-path="logoImagePath"
    :error-message="errorMessage"
  >
    <div class="panel panel-normal">
      <div class="panel_inner">
        <form
          id="edit_school_account_password"
          ref="form"
          class="edit_school_account"
          action="/school_accounts"
          accept-charset="UTF-8"
          method="post"
          @submit.prevent="handleSubmit"
        >
          <input type="hidden" name="_method" value="patch" autocomplete="off" /><input
            type="hidden"
            name="authenticity_token"
            :value="authenticityToken"
            autocomplete="off"
          />
          <div class="sign-Form">
            <div class="sign-Form">
              <div class="sign-Form_item">
                <div class="sign-Form_heading">
                  <label for="school_account_password">新しいパスワード</label>
                  <div>
                    <li>10文字以上</li>
                    <li>英小文字・英大文字・数字・記号のうち3種類以上を含む</li>
                    <li>使用可能文字：半角英数字と一部記号（!@#$%^&*）</li>
                  </div>
                </div>
                <div class="sign-Form_input">
                  <template v-if="passwordType === 'password'">
                    <input
                      id="school_account_password"
                      v-model="password"
                      autofocus
                      required
                      placeholder="例）qazwsxedc"
                      class="form-Item_inputText"
                      type="password"
                      name="school_account[password]"
                      @input="validatePassword"
                    /><span @click="showPassword"
                      ><i class="icon-visibility form-Item_showPassword"></i
                    ></span>
                  </template>
                  <template v-if="passwordType === 'text'">
                    <input
                      id="school_account_password"
                      v-model="password"
                      class="form-Item_inputText"
                      type="text"
                      name="school_account[password]"
                      @input="validatePassword"
                    /><span @click="hidePassword"
                      ><i class="icon-visibility_off form-Item_showPassword"></i
                    ></span>
                  </template>
                </div>
                <div v-if="passwordError" class="error-message">{{ passwordError }}</div>
              </div>
              <div class="sign-Form_item">
                <div class="sign-Form_heading">
                  <label for="school_account_password_confirmation">確認用パスワード</label>
                </div>
                <div class="sign-Form_input">
                  <template v-if="passwordConfirmationType === 'password'">
                    <input
                      id="school_account_password_confirmation"
                      v-model="passwordConfirmation"
                      required
                      placeholder="再度パスワードを入力してください"
                      class="form-Item_inputText"
                      type="password"
                      name="school_account[password_confirmation]"
                      @input="validatePasswordConfirmation"
                    /><span @click="showPasswordConfirmation"
                      ><i class="icon-visibility form-Item_showPassword"></i
                    ></span>
                  </template>
                  <template v-if="passwordConfirmationType === 'text'">
                    <input
                      id="school_account_password_confirmation"
                      v-model="passwordConfirmation"
                      class="form-Item_inputText"
                      type="text"
                      name="school_account[password_confirmation]"
                      @input="validatePasswordConfirmation"
                    /><span @click="hidePasswordConfirmation"
                      ><i class="icon-visibility_off form-Item_showPassword"></i
                    ></span>
                  </template>
                </div>
                <div v-if="passwordConfirmationError" class="error-message">
                  {{ passwordConfirmationError }}
                </div>
              </div>
            </div>
          </div>

          <p class="sign-Form_buttonArea">
            <input
              type="submit"
              name="commit"
              value="登録して次へ"
              class="button-md button-normal"
            />
          </p>
        </form>
      </div>
    </div>
  </FacilityRegistrationLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import FacilityRegistrationLayout from 'shared/templates/FacilityRegistrationLayout.vue'
import usePasswordRules from 'pages/account_registration_page/use-password-form'

defineProps<{
  authenticityToken: string
  logoImagePath: string
  errorMessage?: string | string[]
}>()

type PasswordInputType = 'password' | 'text'

const form = ref<HTMLFormElement | null>(null)
const { newPasswordRules } = usePasswordRules()
const password = ref('')
const passwordError = ref('')
const passwordType = ref<PasswordInputType>('password')
const passwordConfirmation = ref('')
const passwordConfirmationError = ref('')
const passwordConfirmationType = ref<PasswordInputType>('password')

const showPassword = () => {
  passwordType.value = 'text'
}
const hidePassword = () => {
  passwordType.value = 'password'
}
const showPasswordConfirmation = () => {
  passwordConfirmationType.value = 'text'
}
const hidePasswordConfirmation = () => {
  passwordConfirmationType.value = 'password'
}

const validatePassword = () => {
  const result = newPasswordRules[0](password.value)
  passwordError.value = result === true ? '' : result
}
const validatePasswordConfirmation = () => {
  passwordConfirmationError.value =
    passwordConfirmation.value === password.value ? '' : 'パスワードが一致しません。'
}

const handleSubmit = () => {
  validatePassword()
  validatePasswordConfirmation()

  if (passwordError.value || passwordConfirmationError.value) {
    return
  }

  if (form.value) {
    form.value.submit()
  }
}
</script>
